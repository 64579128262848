import logo from '../logo.svg';
import '../App.css';

import React from "react";
import { NextUIProvider, createTheme , Container, Grid, Button, Text, Col, Card, Avatar, Spacer, Collapse  } from '@nextui-org/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid'

import Bin from '../components/Bin'
import ReportModal from '../components/ReportModal'
import AdressModal from '../components/AdressModal'
import MobileModal from '../components/MobileModal'
import TrashHint from '../components/TrashHint'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import CalendarGrid from '../components/CalendarGrid';
const queryClient = new QueryClient()

const useStickyState = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = React.useState(() => {
      try {
          const value = window.localStorage.getItem(keyName);

          if (value) {
              return JSON.parse(value);
          } else {
              window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
              return defaultValue;
          }
      } catch (err) {
          return defaultValue;
      }
  });

  const setValue = newValue => {
      try {
          window.localStorage.setItem(keyName, JSON.stringify(newValue));
      } catch (err) {}
      setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default function Home() {

  const darkTheme = createTheme({
    type: 'dark',
    theme: {
      fonts: {
        sans: "Poppins"
      }
    }
  });

  const lightTheme = createTheme({
    type: 'light',
    theme: {
      fonts: {
        sans: "Poppins"
      }
    }
  });

  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 630;

  const [currentBin, setCurrentBin] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handler = (bin) => { 
    setOpen(true)
    setCurrentBin(bin)
  };

  const [locationOpen, setLocationOpen] = React.useState(false);
  const handleLocation = () => setLocationOpen(true);

  const binList = [
    {
      name: "General",
      secondaryColor: "#E4E4E4",
      primaryColor: "#6B6B6B",
    },
    {
      name: "Organic",
      secondaryColor: "#D1B8A1",
      primaryColor: "#A87547",
    },
    {
      name: "Plastic",
      secondaryColor: "#F6EECF",
      primaryColor: "#FFCF55",
    },
    {
      name: "Paper",
      secondaryColor: "#D9DFF6",
      primaryColor: "#7876EC",
    }
  ]

  const hintList = [
    "You can dispose of textiles such as bedding or curtains in the used clothing container!",
    "You don't have to rinse yogurt cups!",
    "You can dispose of receipts and coated paper in the residual waste.",
    "Cardboard boxes stained with food residues, such as pizza boxes, belong in the residual waste!"
  ]

  var dtNow = new Date();
  var intTZOffset = dtNow.getTimezoneOffset() * 60000;
  var intNow = dtNow.getTime() - intTZOffset;
  var intDay = Math.floor(intNow / 86400000);
  var intQuoteToDisplay = intDay % hintList.length;
  var quote = hintList[intQuoteToDisplay] 

  const [currentAdress, setCurrentAdress] = useStickyState("adressData", {housenumber: "", adress: "Please select address"});

  return (
  <QueryClientProvider client={queryClient}>
    <NextUIProvider theme={lightTheme}>
      <Container>
        <Spacer y="0.25" />
        <Text h2>Bin Overview</Text>
        <Text h4 onClick={handleLocation} style={{color:"#6B6B6B"}}>{currentAdress.adress.replace("(", " " + currentAdress.housenumber + " (")} &nbsp; <FontAwesomeIcon icon={faPencilAlt} /></Text>
          <Grid.Container gap={2} justify="flex-start">
            {binList.map((item, index) => (
              <Grid xs={6} sm={3}>
                <Bin onPress={() => handler(item.name)} {...item} currentAdress={currentAdress} />
              </Grid>
            ))}
          </Grid.Container>
          <Spacer y="0.25" />
          <Text h2 b>Tips of the day</Text>
          <Text h5 style={{color:"#6B6B6B"}}> { quote } </Text>
          <Col wrap="wrap" justify="center" align="center" css={{ marginTop: "1rem" }}>
          <Link to={"/info"}><Button flat>View sorting guide</Button></Link>
          </Col>
          <Grid.Container><Spacer y={1} /></Grid.Container>
          <Text h2 b>Pick Up Calendar</Text>
          <CalendarGrid/>
          <Grid.Container><Spacer y={1} /></Grid.Container>
      </Container>
      <MobileModal open={!isMobile}/>
      <ReportModal currentBin={currentBin} open={open} onClose={() => setOpen(false)} currentAdress={currentAdress}/>
      <AdressModal submit={setCurrentAdress} open={locationOpen} onClose={() => setLocationOpen(false)}/>
      <ToastContainer/>
    </NextUIProvider>
  </QueryClientProvider>
  );
}
