
import { Grid, Text, Card, Avatar  } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/fontawesome-free-solid'

function CalendarCard(props) {
    return (
        <Card css={{ p: "$6" }} isPressable isHoverable>
            <Card.Header>
            <Avatar squared icon={<FontAwesomeIcon icon={faTrash} color="grey" />} css={{ ".nextui-avatar-bg" : { background: props.color } }}  />
            <Grid.Container css={{ pl: "$6" }}>
                <Grid xs={12}>
                <Text h4 css={{ lineHeight: "$xs" }}>
                    {props.name}&nbsp;&nbsp;
                </Text>
                </Grid>
                <Grid xs={12}>
                <Text css={{ color: "$accents8" }}> { props.date === "loading" ? "Loading..." : new Date(props.date).toLocaleDateString('de-DE', { weekday: 'short', month: 'numeric', day: 'numeric' })}</Text>
                </Grid>
            </Grid.Container>
            </Card.Header>
        </Card>
    )
}

export default CalendarCard;