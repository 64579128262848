import React from "react";
import {
  Modal,
  Input,
  Row,
  Checkbox,
  Button,
  Text,
  excludedInputPropsForTextarea,
} from "@nextui-org/react";

import { toast } from "react-toastify";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";

export default function ReportModal(props) {

  const queryClient = useQueryClient();

  const mutation = useMutation(
    value => {
      return fetch("https://trashbin.felixcodes.workers.dev/reports/send", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ data: value }),
      })
    },
    {
      onSuccess: async res => {
        if (!res.ok) {
          toast.error("Please wait before reporting this bin again!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: false,
          });
          return
        }        
        toast.success("Report successful!", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: false,
        });
        queryClient.setQueryData(["bin", props.currentBin], await res.json());
      }
    }
  );

  const { open, onClose, currentAdress } = props;
  const closeHandler = (value) => {
    mutation.mutate({ value, currentAdress, bin: props.currentBin });
    onClose();
  };

  if(currentAdress.adress === "Please select address"){
    return (
      <div>
        <Modal closeButton blur open={open} onClose={onClose}>
          <Modal.Body>
            <Row wrap="wrap" justify="center" align="center">
              <Text size={18}>
                Please select your address first!
              </Text>
              </Row>
            </Modal.Body>
          </Modal>
    </div>
    )
  }

  return (
    <div>
      <Modal closeButton blur open={open} onClose={onClose}>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            How full is the bin?
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Button.Group
            size="xl"
            vertical
            color="gradient"
            bordered
            animated="false"
          >
            <Button onPress={() => closeHandler(100)}>100%</Button>
            <Button onPress={() => closeHandler(75)}>75%</Button>
            <Button onPress={() => closeHandler(50)}>50%</Button>
            <Button onPress={() => closeHandler(25)}>25%</Button>
            <Button onPress={() => closeHandler(0)}>0%</Button>
          </Button.Group>
        </Modal.Body>
        <Modal.Footer>
          <Row wrap="wrap" justify="center" align="center">
            <Button auto light onClick={onClose}>
              Cancel
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
