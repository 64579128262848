
import { Collapse, Text, Avatar  } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/fontawesome-free-solid'

function TrashHint(props){
  return(
    <Collapse
        id={props.name.replace(/ü/g, 'u')}
        title={<Text h4>{props.name}</Text>}
        subtitle={props.subtitle}
        expanded={props.expanded}
        contentLeft={
            <Avatar squared icon={<FontAwesomeIcon icon={faTrash} color="grey" />} css={{ ".nextui-avatar-bg" : { background: props.color } }}  />
        }
    >
        <Text>{props.text}</Text>
    </Collapse>
  )
}

export default TrashHint;