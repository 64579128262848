import React from "react";
import {
  Modal,
  Input,
  Row,
  Checkbox,
  Button,
  Text,
  excludedInputPropsForTextarea,
} from "@nextui-org/react";

export default function ReportModal(props) {
  const { open } = props;

  return (
    <div>
      <Modal preventClose blur open={open} >
        <Modal.Header>
          <Text id="modal-title" size={18}>
            As per project scope, this application is meant to be used on mobile devices only. Please simulate mobile use via your browsers developer tools.
          </Text>
        </Modal.Header>
      </Modal>
    </div>
  );
}
