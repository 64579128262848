import { React, useEffect, useState } from "react";
import {
  Modal,
  Input,
  Row,
  Checkbox,
  Button,
  Text,
  Spacer,
  excludedInputPropsForTextarea,
} from "@nextui-org/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow } from '@fortawesome/fontawesome-free-solid'
import { Loading } from '@nextui-org/react';
import { toast } from 'react-toastify';

export default function ReportModal(props) {
  const { submit, open, onClose } = props;
  const closeHandler = () => {
    toast.success('Address changed successfully!', {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: false,
    });
    onClose()
    submit({ adress: document.getElementById("adress").value, housenumber: document.getElementById("housenumber").value })
  }

  const [adressData, setAdressData] = useState({});
  const [housenumberData, setHousenumberData] = useState({});

  const [numberEnabled, setNumberEnabled] = useState(false);

  useEffect( () => { fetch('https://www.swp-potsdam.de/api/garbageservice/addresses/lookup?street=')
  .then(response => response.json())
  .then(data => setAdressData(data)); }, []) 

  const handleAdressChange = () => {
    fetch('https://www.swp-potsdam.de/api/garbageservice/addresses/lookup?street='+ document.getElementById("adress").value + '&housenumber=')
      .then(response => response.json())
      .then(data => { 
        setHousenumberData(data) 
        setNumberEnabled(true)
      });
  }

  return (
    <div>
      <Modal closeButton blur open={open} onClose={onClose}>
        <Modal.Header>
          <Text id="modal-title" size={18}>
            Settings
          </Text>
        </Modal.Header>
        <Modal.Body>
          <Spacer y={0.5} />
          <Input
            id="adress"
            labelPlaceholder="Your Address"
            list="postdamAdressList"
            contentRight={
              <FontAwesomeIcon icon={faLocationArrow}/>
            }
            onChange={handleAdressChange}
          />
          <Spacer y={0.3} />
          <Input id="housenumber" disabled={!numberEnabled} list="potsdamHousenumberList" labelPlaceholder="House Number" />
        </Modal.Body>
        <Modal.Footer>
          <Row wrap="wrap" justify="center" align="center">
            <Button flat color="success" auto onPress={closeHandler}>
              Confirm
            </Button>
            <Button auto light onClick={onClose}>
              Cancel
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      {adressData && adressData.length > 0 && (
        <datalist id="postdamAdressList">
          {adressData.map(adress => (
            <option key={adress} value={adress} />
          ))}
        </datalist>
      )}

      {housenumberData && housenumberData.length > 0 && (
        <datalist id="potsdamHousenumberList">
          {housenumberData.map(housenumber => (
            <option key={housenumber} value={housenumber} />
          ))}
        </datalist>
      )}

    </div>
  );
}
