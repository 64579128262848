
import { Card, Row , Text, Col, Button } from '@nextui-org/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/fontawesome-free-solid'
import { useQuery, useQueryClient } from '@tanstack/react-query'

function getDateFromBinType(type, obj){
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].name === type) {
      return obj[i].date;
    }
  }
}

function Bin(props) {
  
  let { isLoading, error, data } = useQuery(["bin", props.name], () =>
    fetch('https://trashbin.felixcodes.workers.dev/reports/info?street=' + props.currentAdress.adress + '&number=' + props.currentAdress.housenumber + '&bin=' + props.name).then(res =>
      res.json()
    )
  )

  let { isLoading: dateLoading, data: dateData } = useQuery(["calendar"], () =>
    fetch('https://trashbin.felixcodes.workers.dev/calendar').then(res =>
        res.json()
    )
  )

  let dateString = ""

  if(dateLoading){
    dateString = "Loading..."
  } else {
    dateString = new Date(getDateFromBinType(props.name, dateData)).toLocaleDateString('de-DE', {day: "numeric", month: "numeric", year: "numeric"})
  }

  let text = ""

  if (isLoading){
     text = "..."
  } else if (error) {
     text = "ERROR"
     console.log(error)
  } else {
     text = data.percentage + "%"
  }

  return (
    <Card isHoverable isPressable onClick={props.onPress}>  
      <Card.Body css={{ p: 0, backgroundColor: props.secondaryColor }}>
        <Col wrap="wrap" justify="center" align="center" css={{ marginTop: "0.5rem" }}>
          <Text b>{props.name}</Text>
          </Col>
          <Col wrap="wrap" justify="center" align="center">
          <Text i>{dateString}</Text>
        </Col>
        <Row wrap="wrap" justify="center" align="center" css={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
            <FontAwesomeIcon icon={faTrash} size="6x" color={props.primaryColor} />
            <b class="fa-layers-text" data-fa-transform="shrink-6 right-2 up-2">{text}</b>
        </Row>
      </Card.Body>
      <Card.Footer css={{ justifyItems: "flex-start", backgroundColor: "black" }}>
        <Row wrap="wrap" justify="center" align="center">
          <Text b css={{ color: "white" }}>Report</Text>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export default Bin;