import CalendarCard from '../components/CalendarCard'
import { Grid } from '@nextui-org/react';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Link } from "react-router-dom";

export default function CalendarGrid(){

    let { isLoading, error, data, refetch } = useQuery(["calendar"], () =>
        fetch('https://trashbin.felixcodes.workers.dev/calendar').then(res =>
            res.json()
        )
    )

    let calendarList;

    if (isLoading){
        calendarList = [
                {
                    "name": "General",
                    "color": "#E4E4E4",
                    "date": "loading"
                  },
                  {
                    "name": "Organic",
                    "color": "#D1B8A1",
                    "date": "loading"
                  },
                  {
                    "name": "Plastic",
                    "color": "#F6EECF",
                    "date": "loading"
                  },
                  {
                    "name": "Paper",
                    "color": "#D9DFF6",
                    "date": "loading"
                  }
        ]
     } else if (error) {
        console.log(error)
     } else {
        calendarList = data
        console.log(data)
     }

    return (
        <Grid.Container gap={2} justify="flex-start">
            {calendarList.map((item, index) => (
                <Grid xs sm={3}>
                    <Link to={"/info#" + item.name} style={{width: "100%"}}>
                        <CalendarCard {...item} />
                    </Link>
                </Grid>
            ))}
        </Grid.Container>
    );

}