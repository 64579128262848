import { React } from "react";
import { NextUIProvider, createTheme, Container, Grid, Button, Text, Col, Card, Avatar, Spacer, Collapse  } from '@nextui-org/react';
import TrashHint from "../components/TrashHint";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid'
import { Link } from "react-router-dom";
import { useLayoutEffect, useState } from 'react';

const darkTheme = createTheme({
    type: 'dark',
    theme: {
      fonts: {
        sans: "Poppins"
      }
    }
  });

  const lightTheme = createTheme({
    type: 'light',
    theme: {
      fonts: {
        sans: "Poppins"
      }
    }
  });

const hintList = [
    {
        name: "Organic", 
        color: "#D1B8A1",
        subtitle: "Brown lid",
        text: "What goes in: Kitchen and garden waste, such as bread scraps, meat scraps, cooked food scraps, green waste, coffee filters, paper towels, leaves, tea bags, cut and potted plants, spoiled food (without packaging), newspaper for wrapping."
    },
    {
        name: "General", 
        color: "#E4E4E4",
        subtitle: "Black Bin",
        text: "What goes in: Waste that cannot be recycled, such as floppy disks, hygiene products, ceramics, small animal litter, porcelain, vacuum cleaner bags, mirrors, wallpaper remnants, video tapes, diapers, cigarette waste."
    },
    {
        name: "Plastic", 
        color: "#F6EECF",
        subtitle: "Yellow bin, yellow bag",
        text: "What goes in: Disposable packaging made of metal, plastics and composites, such as beverage containers, yogurt cups, food cans, plastic bags, plastic film, shampoo and shower gel bottles, spray cans, toothpaste tubes."
    },
    {
        name: "Paper", 
        color: "#D9DFF6",
        subtitle: "Blue Bin",
        text: "What goes in: Recycled paper and packaging made of paper or cardboard and cardboard packaging, such as envelopes, brochures, books, wrapping paper, catalogs, brochures, newspapers, magazines."
    }
]

function Info() {

    const [expanded, setExpanded] = useState(false);

    useLayoutEffect(() => {
        let anchor = window.location.hash.split('#')[1]
        console.log(anchor)
        if (anchor) {
          anchor = anchor.replace(/ü/g, 'u');
          const accExists = hintList.find(q => q.name.replace(/ü/g, 'u') === anchor)
          if (accExists) {
            setExpanded(anchor);
            const anchorEl = document.getElementById(anchor);
            anchorEl.scrollIntoView({behavior: "smooth"});
          }
        }
      }, []);

  return (
    <NextUIProvider theme={lightTheme}>
      <Container>
        <Text h2><Link to={"/"}><FontAwesomeIcon icon={faArrowLeft} color="black" /></Link> Waste disposal guide</Text>
        <Grid.Container><Spacer y={1} /></Grid.Container>
        <Text h5 style={{color:"#6B6B6B"}}>Here you will find an overview of how to separate your waste properly.</Text>
        <Grid.Container><Spacer y={1} /></Grid.Container>
        <Collapse.Group splitted divider="true">
            {hintList.map((item, index) => (
                <TrashHint expanded={expanded === item.name.replace(/ü/g, 'u')} {...item} />
            ))}
        </Collapse.Group>
      </Container>
    </NextUIProvider>
  )
}

export default Info;
